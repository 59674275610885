import Head from 'next/head'
interface MetaHeadProps {
    title: string
    description: string
    image: string
    type?: string
    url?: string
}

export const MetaHead: React.FC<MetaHeadProps> = ({
    title,
    description,
    image,
    type,
    url,
}) => {
    return (
        <Head>
            {!!title && (
                <>
                    <title>{title}</title>
                    <meta property="og:title" content={title}></meta>
                </>
            )}
            {!!description && (
                <>
                    <meta
                        property="og:description"
                        content={description}
                    ></meta>
                    <meta property="description" content={description}></meta>
                </>
            )}
            {image ? (
                <>
                    <meta property="og:image" content={image}></meta>
                    <meta property="twitter:image" content={image} />
                </>
            ) : (
                <>
                    <meta
                        property="og:image"
                        content={
                            'https://images.prismic.io/new-origo/c692891f-b747-4f72-99c4-c60c085a0309_Landmannalaugar_cropped.jpg?auto=compress,format'
                        }
                    ></meta>
                    <meta
                        property="twitter:image"
                        content={
                            'https://images.prismic.io/new-origo/c692891f-b747-4f72-99c4-c60c085a0309_Landmannalaugar_cropped.jpg?auto=compress,format'
                        }
                    />
                </>
            )}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="og:type" content={type ? type : 'website'}></meta>
            {url && <meta property="og:url" content={url} />}
        </Head>
    )
}
