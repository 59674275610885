import { LinkContextProvider } from '@orbit/prismic'
import {
    OrigoFooterProps,
    NavigationProps,
    OrbitFooter,
} from '@orbit/ui/prismic'
import { Header } from '@orbit/ui/prismic'
import {
    Box,
    Button,
    NotificationBanner,
    useNotification,
    Text,
} from '@orbit/ui/core'
import { IAllNestedPaths } from '../types'
import React, { useContext, useEffect, useState } from 'react'
import { scrollIntoView } from '../utils/scrollIntoView'
import { useRouter } from 'next/router'
import { PreviewButtonWrapper } from '../styles/PreviewButton.css'
import { useWindowSize } from 'react-use'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { PrismicRichText } from '@prismicio/react'
import useSwr from 'swr'
import { TNotification } from '../pages/api/notifications'
import { HeaderContext } from '@oribt/context'

interface PageLayoutProps {
    children: React.ReactNode
    menu?: NavigationProps
    allNestedPaths?: IAllNestedPaths
    footer?: OrigoFooterProps
    lang?: 'is' | 'en-gb'
}

const fetcher = (url: string) => fetch(url).then((r) => r.json())

export const PageLayout: React.FC<PageLayoutProps> = ({
    menu,
    children,
    allNestedPaths,
    footer,
    lang = 'is',
}) => {
    const router = useRouter()
    const [footerIsLoading, setFooterIsLoading] = useState(true)
    const { width } = useWindowSize()
    const headerContext = useContext(HeaderContext)
    const [isMobile, setIsMobile] = useState(false)
    const [notification, setNotification] = useState<TNotification>()
    const showNotification = useNotification(
        notification ? notification.nid : undefined
    )

    const { data: notificationData } = useSwr<TNotification>(
        '/api/notifications',
        fetcher
    )
    useEffect(() => {
        if (notificationData) {
            setNotification(notificationData)
        }
    }, [notificationData])

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        if (window) {
            const anchor = window.location.hash.split('#')[1]
            if (anchor) scrollIntoView(anchor)
        }
    }, [])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFooterIsLoading(false)
        }, 200)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    const notificationColor =
        notification &&
        showNotification &&
        (notification.type === 'info' || notification.type === undefined)
            ? { color: theme.colors.neutral[100] }
            : { color: theme.colors.neutral[700] }

    return (
        <LinkContextProvider allNestedPaths={allNestedPaths}>
            {notification && showNotification && (
                <Box
                    boxStyles={{
                        paddingTop: '2.5rem',
                    }}
                >
                    <NotificationBanner
                        type={notification.type}
                        id={notification.nid}
                    >
                        <Box width={isMobile ? 80 : 'full'} margin="auto">
                            <PrismicRichText
                                field={notification.content}
                                components={{
                                    paragraph: ({ children }) => (
                                        <Text style={notificationColor} my={0}>
                                            {children}
                                        </Text>
                                    ),
                                    hyperlink: ({ children, node }) => {
                                        return (
                                            <a
                                                style={notificationColor}
                                                href={node.data.url}
                                            >
                                                {children}
                                            </a>
                                        )
                                    },
                                }}
                            />
                        </Box>
                    </NotificationBanner>
                </Box>
            )}
            <Header navigation={menu} lang={lang} />

            <Box
                boxStyles={{
                    paddingTop: headerContext.isNotificationOpen
                        ? '8rem'
                        : '5.5rem',
                }}
                width="full"
                display="block"
            >
                <main id="main">{children}</main>
            </Box>
            {router.isPreview && (
                <Box extraClassName={PreviewButtonWrapper}>
                    <Button
                        type="text"
                        onClick={() => router.push('/api/exitPreview')}
                        color="primary"
                    >
                        Hætta forskoðun
                    </Button>
                </Box>
            )}
            {!footerIsLoading && footer && <OrbitFooter {...footer} />}
        </LinkContextProvider>
    )
}

export default PageLayout
