export const scrollIntoView = (anchor: string) => {
    if (window) {
        const anchordElement = window.document.querySelector(
            `[data-anchorid="${decodeURI(anchor)}"]`
        )

        if (anchordElement) {
            setTimeout(() => {
                anchordElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                })
            }, 1000)
        }
    }
}
