import { SliceZone } from '@prismicio/react'
import {
    KeyTextField,
    PrismicDocumentWithUID,
    SliceZone as PrismicSlices,
} from '@prismicio/types'
import {
    components,
    OrigoFooterProps,
    NavigationProps,
} from '@orbit/ui/prismic'
import { GetStaticProps } from 'next'
import React from 'react'
import { createClient } from '@orbit/prismic'
import { OrigoRoutes } from '../utils/routes'
import PageLayout from '../layouts/PageLayout'
import { getMenu } from './api/getMenu'
import { getFooter } from './api/getFooter'
import { captureException } from '@sentry/nextjs'
import { MetaHead } from '../utils/MetaHead'

type THomePageData = {
    body: any
    metadescription: KeyTextField
    ogimageidfullpath: KeyTextField
    metatitle: KeyTextField
}
interface IProps {
    page: PrismicDocumentWithUID<THomePageData>
    lang: 'is' | 'en-gb'
    menu: NavigationProps
    footer: OrigoFooterProps
}

export const HomePage: React.FC<IProps> = ({ page, lang, menu, footer }) => {
    return (
        <>
            <MetaHead
                title={page.data.metatitle ? page.data.metatitle : ''}
                description={
                    page.data.metadescription ? page.data.metadescription : ''
                }
                image={
                    page.data.ogimageidfullpath
                        ? page.data.ogimageidfullpath
                        : ''
                }
            />
            <PageLayout menu={menu} footer={footer}>
                <div>
                    <SliceZone
                        slices={page.data.body}
                        components={components}
                        context={{ lang: lang, id: page.id }}
                    />
                </div>
            </PageLayout>
        </>
    )
}

export const getStaticProps: GetStaticProps<IProps> = async ({
    previewData,
}) => {
    try {
        const prismicClient = createClient({
            previewData: previewData,
            routes: OrigoRoutes,
        })
        const page = await prismicClient.getSingle<
            PrismicDocumentWithUID<IProps['page']['data']>
        >('homepage', {
            lang: 'is',
        })

        const menu = await getMenu('is', previewData)
        const footer = await getFooter('is', previewData)

        return {
            revalidate: 10,
            props: {
                page: page,
                menu,
                lang: 'is',
                footer,
            },
        }
    } catch (error) {
        captureException(error, {
            tags: {
                page: 'Homepage',
                uid: '/',
                lang: 'is',
            },
        })
        return {
            notFound: true,
            revalidate: 10,
        }
    }
}

export default HomePage
